import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link, navigate } from 'gatsby';
import { Trans, withI18n } from '@lingui/react';
import _ from 'lodash';
import { prefix } from '../i18n-config';

class Home extends Component {
  constructor(props) {
    super(props);
    const { pageContext } = this.props;
    this.langPrefix = prefix(pageContext.lang);
  }

  render() {
    const { location } = this.props;
    const lang = this.langPrefix;
    return (
      <div className="page front-page">
        <Helmet defaultTitle="GeneSight - How Depression Feels?" titleTemplate="%s - How Depression Feels?">
          <meta name="og:type" content="website" />
          <meta name="og:site_name" content="GeneSight - How Depression Feels?" />
          <title>GeneSight - How Depression Feels?</title>
          <link
            rel="canonical"
            href={`http://knowmentalhealth.com${location.pathname}`}
          />
          <html lang={lang} />
          <meta name="description" content="The everyday challenges experienced by real people who suffer from depression" />
        </Helmet>
        <div className="container">
          <div className="left">
            <div className="header">
              <h1>How <span>Depression</span> Feels</h1>
              <h2>The everyday challenges experienced by real people with depression</h2>
            </div>
            <div className="body">
              <h3>The way we talk to ourselves matters.</h3>
              <h4>This is especially true when it comes to depression.</h4>
              <p className="m-b-0">In fact, 83% of those with depression agree life would be easier if others could understand&nbsp;their&nbsp;depression.</p>
              <p>Depression impacts every facet of your life – emotional, relationships, self-awareness, physical. This experience is designed to give you a taste of what depression feels like — and sounds like — from the inside out. <b>Not everyone who has depression will experience it just like this</b>, but this experience is meant to give you a sense for how hard it is to go about your daily life when battling a debilitating disorder like depression.</p>
              <div className="button-wrapper">
                <div className="warning">
                  <span>
                    <b>Trigger Warning</b>
                    Upsetting Language<br />
                    Unsuitable for young listeners
                  </span>
                </div>
                <div className="heart">
                  <span>
                    <b>Look for this icon</b>
                    if you feel distressed:<br />
                    Click the heart to exit
                  </span>
                </div>
                <Link to={`/how-depression-feels`} className="explore-btn appear">
                  <span>Enter Experience</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="right">
            <img src="/genesight-logo.png" alt="logo" />
            <img className="dbsa" src="/dbsa-white.png" alt="dbsa-logo" />
          </div>
        </div>
      </div>
    );
  }
}

export default withI18n()(Home);
